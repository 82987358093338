import { ATTRIBUTION_ID_GLOBAL_KEY } from 'constants/tracking'

import { generateId } from 'utils/generate-id'
import Cookies from 'js-cookie'
import { routes } from 'utils'
import trimStart from 'lodash.trimstart'
import { sendEventToGTMDataLayer } from 'utils/gtm'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import { trackSignupFormError, getAttributionUserId } from 'components/tracking'

import { SIGNUP_CONSENTS_STORAGE_KEY } from './consents/constants'
import {
  FIRST_LAST_NAME_REGEX,
  SIGNUP_PROVIDER_COOKIE_NAME,
  SIGNUP_PROVIDER_OKTA,
  COOKIE_CONFIG,
  EXPERIMENT_FINGERPRINT,
  PARTNER_STACK_ENCODED_KEY,
  PARTNER_STACK_SESSION_ID,
  SAASQUATCH_REFERRAL_CODE,
  SIGNUP_DEVICE_COOKIE_NAME,
  SIGNUP_USER_AGENT_COOKIE_NAME,
  SIGNUP_EXTRA_COOKIE_NAME,
  COOKIES_TO_CHECK,
  SIGNUP_STATE_KEY,
  OKTA_LOCALES,
  CELLO_REFERRAL_CODE,
} from './constants'
import {
  areTermsAndConditionsValid,
  getConsentsStatus,
} from './consents/helpers'

/**
 * Name and LastName must be provided to Okta for signup operations
 *
 * The only reason this function assumes that email will contain a dot in between
 * name and last name is because the onboard survey prefills emails using that pattern
 *
 * Since there is no way to identify name and last name from email addresses, this function
 * uses FIRST_LAST_NAME_REGEX and fallsback to prefilling the name in case RegEx does not match
 */
export const extractFirstAndLastNameFromEmail = email => {
  const [localPart] = email.split('@')

  const [, firstNameMatch, lastNameMatch] = localPart.match(
    FIRST_LAST_NAME_REGEX
  )

  const firstName = firstNameMatch || localPart
  const lastName = lastNameMatch || ''

  return {
    firstName,
    lastName,
  }
}

function addCookiesData(signupData) {
  const cookies = COOKIES_TO_CHECK.reduce((acc, cookie) => {
    const value = Cookies.get(cookie)
    return value ? { ...acc, [cookie]: value } : acc
  }, {})

  return {
    ...signupData,
    cookies,
  }
}

function addParamsData(signupData) {
  const currentSearchParams = new window.URLSearchParams(window.location.search)
  return {
    ...signupData,
    searchParams: {
      ...Object.fromEntries(currentSearchParams),
      [ATTRIBUTION_ID_GLOBAL_KEY]: getAttributionUserId(),
    },
  }
}

function addReferrer(signupData) {
  const data = { ...signupData }
  if (document.referrer) {
    data.referrer = document.referrer
  }
  return data
}

function addOptimizelyData(signupData) {
  const optimizelyUserId = Cookies.get(EXPERIMENT_FINGERPRINT)
  return optimizelyUserId ? { ...signupData, optimizelyUserId } : signupData
}

function addCelloReferralCode(signupData) {
  const celloReferral = Cookies.get(CELLO_REFERRAL_CODE)
  return celloReferral ? { ...signupData, celloReferral } : signupData
}

function addReferralCode(signupData) {
  const currentSearchParams = new window.URLSearchParams(window.location.search)
  const referralCode = currentSearchParams.get(SAASQUATCH_REFERRAL_CODE)
  return referralCode ? { ...signupData, referralCode } : signupData
}

function addPartnerStackData(signupData) {
  const gspk = Cookies.get(PARTNER_STACK_ENCODED_KEY)
  const gsxid = Cookies.get(PARTNER_STACK_SESSION_ID)

  let data

  if (gspk && gsxid) {
    try {
      data = {
        ...signupData,
        partnerstack: {
          partnerKey: window.atob(gspk),
          userId: gsxid,
        },
      }
    } catch (e) {
      data = signupData
    }
  }

  return data
}

function addOneTapData(isOneTap = false) {
  return signupData => {
    return isOneTap ? { ...signupData, isOneTap: 'yes' } : signupData
  }
}

const compose =
  (...functions) =>
  args =>
    functions.reduceRight((arg, fn) => fn(arg), args)

function buildSignupExtra(isOneTap = false) {
  return compose(
    addCookiesData,
    addParamsData,
    addReferrer,
    addOptimizelyData,
    addCelloReferralCode,
    addReferralCode,
    addOneTapData(isOneTap),
    addPartnerStackData
  )({})
}

export function getCookieConfig(expirationInDays = '') {
  const config = {
    ...COOKIE_CONFIG,
  }

  if (expirationInDays) {
    config.expires = expirationInDays
  }

  return config
}

function setSignupProviderCookie(provider) {
  Cookies.set(SIGNUP_PROVIDER_COOKIE_NAME, provider, getCookieConfig(365))
}

function setPartnerStackCookiesFromParams() {
  const currentSearchParams = new window.URLSearchParams(window.location.search)

  const cookieConfig = getCookieConfig(90)

  const gspk = currentSearchParams.get(PARTNER_STACK_ENCODED_KEY)
  const gsxid = currentSearchParams.get(PARTNER_STACK_SESSION_ID)

  if (gspk && gsxid) {
    // persist cookie only if gspk is legit
    try {
      window.atob(gspk)
    } catch (e) {
      return
    }
    Cookies.set(PARTNER_STACK_ENCODED_KEY, gspk, cookieConfig)
    Cookies.set(PARTNER_STACK_SESSION_ID, gsxid, cookieConfig)
  }
}

export function setSignupCookies({ provider, device, isOneTap = false }) {
  const cookieConfig = getCookieConfig(365)
  const sessionCookieConfig = getCookieConfig()

  setSignupProviderCookie(provider)

  Cookies.set(SIGNUP_DEVICE_COOKIE_NAME, device, cookieConfig)
  Cookies.set(SIGNUP_USER_AGENT_COOKIE_NAME, navigator.userAgent, cookieConfig)
  Cookies.set(
    SIGNUP_EXTRA_COOKIE_NAME,
    JSON.stringify(buildSignupExtra(isOneTap)),
    sessionCookieConfig
  )

  setPartnerStackCookiesFromParams()
}

const formatOktaRoute = route => trimStart(route, '/')

const OKTA_REGISTER_COMPLETE_ROUTE = 'signin/register-complete'

export const getSignupWidgetConfig = ({
  locale,
  device,
  t,
  RECAPTCHA_KEY,
  CAPTCHA_ENABLED,
}) => ({
  registration: {
    preSubmit(postData, onSuccess, onFailure) {
      const verifiedTermsAndConditions = areTermsAndConditionsValid()

      if (!verifiedTermsAndConditions) {
        setSignupProviderCookie(SIGNUP_PROVIDER_OKTA)
        trackSignupFormError({
          label: t('auth.form.consents-error'),
        })

        onFailure()
        return
      }

      setSignupCookies({ provider: SIGNUP_PROVIDER_OKTA, device })

      const onSuccessContext = {
        ...postData,
        ...extractFirstAndLastNameFromEmail(postData.email),
        typeformConsents: `{"terms":true,"consents":true,${getConsentsStatus()}}`,
        locale: OKTA_LOCALES[locale],
        preferredLanguage: locale,
        attributionUserId: getAttributionUserId(),
        signupUserAgent: navigator.userAgent,
        signupExtra: JSON.stringify(buildSignupExtra()),
      }

      if (
        CAPTCHA_ENABLED &&
        window.grecaptcha !== undefined &&
        window.grecaptcha.enterprise !== undefined
      ) {
        window.grecaptcha.enterprise
          .execute(RECAPTCHA_KEY, { action: 'SIGNUP' })
          .then(token => {
            const extra = buildSignupExtra()

            extra.recaptchaResponse = token

            onSuccessContext.signupExtra = JSON.stringify(extra)

            onSuccess(onSuccessContext)
          })
        return
      }

      onSuccess(onSuccessContext)
      return
    },
  },
  routes: {
    [formatOktaRoute(routes(LANGUAGE_CODES.english).signup)]: 'register',
    [formatOktaRoute(routes(LANGUAGE_CODES.spanish).signup)]: 'register',
    [formatOktaRoute(routes(LANGUAGE_CODES.french).signup)]: 'register',
    [formatOktaRoute(routes(LANGUAGE_CODES.german).signup)]: 'register',
    [OKTA_REGISTER_COMPLETE_ROUTE]: 'registerComplete',
  },
})

export const onError = err => {
  trackSignupFormError({ label: err })
  // eslint-disable-next-line
  console.error('error', err)
}

const POPUP_STATE_PREFIX = 'ppp___'

export function generateState(newTabState = false) {
  const state = `${newTabState ? POPUP_STATE_PREFIX : ''}${generateId()}`
  const in10Minutes = (1 / (24 * 60)) * 10 // 1day => 24h * 60 mins

  Cookies.set(SIGNUP_STATE_KEY, state, getCookieConfig(in10Minutes))

  return state
}

export function trackSignupSuccessOnGTMDataLayer(device) {
  const consentsValues =
    JSON.parse(window.sessionStorage.getItem(SIGNUP_CONSENTS_STORAGE_KEY)) || {}

  const trackingProperties = {
    attribution_user_id: getAttributionUserId(),
    category: 'publicsite',
    marketing: consentsValues['marketing'],
    tailor_to_needs_agreed: consentsValues['tailor_to_needs_agreed'],
    third_parties_agreed: consentsValues['third_parties_agreed'],
    signup_device: device,
  }
  sendEventToGTMDataLayer('signUp', trackingProperties)
}

export const getLoginHintFromSearchParams = () => {
  if (typeof window === 'undefined') {
    return ''
  }

  return new URLSearchParams(window.location.search).get('login_hint')
}
